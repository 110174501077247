import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import firebase from '../config/firebase';
import 'firebase/database';
import { connect } from 'react-redux';
import Cabeçalho from '../components/Cabeçalho';
import Rodapé from '../components/Rodapé';
import '../style/chat.css';
import { AiOutlineSend } from "react-icons/ai";

function Chat(props) {
  const [mensagem, setMensagem] = useState();
  const [renderMensagem, setrenderMensagem] = useState([]);
  const [canal, setCanal] = useState();
  const [user, setUser] = useState();

  const location = useLocation();
  const locationId = location.pathname.split('/')[2] === undefined ? null : location.pathname.split('/')[2].replace(/%20/g, '') 
  // console.log('locationId', locationId);

 
  const db = firebase.database();
  const msgRef = db.ref().child('messages');
 
  const tempo = new Date().toLocaleTimeString([], { timeStyle: 'short' });

  useEffect(() => {
    setUser(props.usuario);

    if(locationId !== null) {
      firebase.firestore().collection('canais').where('usuario', '==', `${props.usuario}`).get().then( async (resultado) => await resultado.docs.forEach(doc => {
        if(doc.data().titulo.includes(locationId) === true) {
          const novoDb = db.ref().child(`${doc.data().titulo}`);
          setCanal(novoDb);
          console.log('novoDb', novoDb);
          console.log('msgRef', msgRef);
          return novoDb;
        }
      }));
    }
  
  },[props.usuario], [canal], [locationId]);

  function enviarMsg() {
    if(locationId === null || !locationId) {
      msgRef.push({
        "titulo": 'Chat principal',
        "usuario": user,
        "msg": mensagem,
        "hora": tempo,
      })
    }
    setCanal(db.ref().child(locationId));
    canal.push({
      "usuario": user,
      "msg": mensagem,
      "hora": tempo,
    })
  }
      
  function load() {   
    if(locationId === null || !locationId || locationId === undefined) {
      msgRef.on('value', async snapshot => {
        const notif = await snapshot.val();
        const arrNotif = [notif]
        const arr2 = arrNotif === null ? null : arrNotif.map((elem) => Object.values(elem));
        const arr3 = arr2[0].reverse();
        setrenderMensagem(arr3);
      })
    } 
    canal.on('value', async snapshot => {
    const notif = await snapshot.val();
    const arrNotif = [notif]
    const arr2 = arrNotif === null ? null : arrNotif.map((elem) => Object.values(elem));
    const arr3 = arr2[0].reverse();
    setrenderMensagem(arr3);
  })
  }

  // function apertarEnter(target) {
  //   if(target.key === 'Enter') {
  //     console.log('do validate');
  //   }
  // }
  
  return (
    <div onLoad={load} className="div-chat">
      <Cabeçalho titulo={(locationId === null || !locationId) ? 'Chat Principal' : 'Sua sala de Chat'}/>
      <form className="pt-7 pt-5 form-chat">
        <div className="text-center pt-4 d-flex input-btn-div align-items-center">
          <input
            className="input-chat form-control"
            type="text" onChange={ ({ target }) => setMensagem(target.value) }
            // onKeyPress={ (e) => { if(e.key === 'Enter') { enviarMsg(); load() } } }
          />
          <button
            type="button"
            onClick={ () => { enviarMsg(); load() } }
            className="btn-chat"
          >
          <AiOutlineSend size={ 28 }/>
          </button>
        </div>
    
        <div className="chat-box">
          <div className="">
            {renderMensagem.map(({ usuario, msg, hora }, index) => (
              <div key={ index } className="p-chat d-flex my-2 justify-content-between pt-3 px-3">
                <p>
                  <span className="text-primary">{`${ usuario }: `}</span>
                  <span>{`${ msg }`}</span>
                </p>
                <p className="text-secondary">{hora}</p>
              </div>
                ))}
          </div>
        </div>
       </form>
      <Rodapé />
    </div>
    )
  }


const mapStateToProps = (state) => ({
  usuario: state.usuarioReducer.usuarioEmail,
});

export default connect(mapStateToProps, null)(Chat);
