import React, { useState } from 'react';
import firebase from '../config/firebase';
import 'firebase/auth';
import Cabeçalho from '../components/Cabeçalho';
import Rodapé from '../components/Rodapé';
import '../style/recuperarSenha.css';

function RecuperarSenha() {
  const [email, setEmail] = useState();
  const [msg, setMsg] = useState();

  function recuperarSenha() {
    firebase.auth().sendPasswordResetEmail(email).then(resultado => {
      setMsg('Enviamos um link no seu email para você redefinir sua senha!');

    }).catch(() => {
      setMsg('Verifique se o email está correto.');
    })
  }

  return (
    <div>
      <Cabeçalho />
      <div className="recuperar-senha text-center">
        <h1>Recuperar senha</h1>
        <input
          type="email"
          placeholder="Digite seu email"
          onChange={({ target }) => setEmail( target.value )}
        />
        <button type="button" className="mx-auto my-5" onClick={ recuperarSenha }>Recuperar senha</button>
        <span>{ msg }</span>
      </div>
      <Rodapé />
    </div>
  )
}

export default RecuperarSenha;