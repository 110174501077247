import React, { Component } from 'react';
import '../style/canal.css';
import { Link } from 'react-router-dom';
import chat from '../images/chat.svg'
import video from '../images/video.svg'

export default class Login extends Component {
  render() {
    return (
      <div className="todos-canais">
       <Link to="/chat" className="sessao-canal">
         <section>
           <p className="text-center fs-5 fw-light">Chat</p>
          <img src={chat} alt="" className="image-chat"/>
         </section>
         </Link>
       <Link to="/canal-video" className="sessao-canal">
        <section>
          <p className="text-center fs-5 fw-light">Video Chamada</p>
          <img src={video} alt="" className="image-video"/>
        </section>
        </Link>
      </div>
    )
  }
}