import React from 'react';
import { AiOutlineCopyright } from "react-icons/ai";
import '../style/rodape.css';

function Rodapé() {

  return (
    <div className="div-principal-Rodapé">
       <img src={'https://hybri.io/assets/logo.png'} alt="logo-hybri" className="logo-rodape"/>
       <AiOutlineCopyright />
       2022
    </div>
    )
  }

export default Rodapé;