import React, { useState } from 'react';
import { connect } from 'react-redux';
import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import Cabeçalho from '../components/Cabeçalho';
import '../style/cadastrarCanal.css';
import Rodapé from '../components/Rodapé';
import { Link } from 'react-router-dom';

function CadastroNovoCanal(props) {

  const [msgTipo, setMsgTipo] = useState();
  const [titulo, setTitulo] = useState();
  const [tipo, setTipo] = useState();
  const [descricao, setDescricao] = useState();
  const [foto, setFoto] = useState();
  const [carregando, setCarregando] = useState();

  const storage = firebase.storage();
  const db = firebase.firestore();

  function cadastrar() {
    setMsgTipo(null);
    setCarregando(true);

    storage.ref(`imagens/${foto.name}`).put(foto).then(() => {
      db.collection('canais').add({
        titulo: titulo,
        tipo: tipo,
        descricao: descricao,
        usuario: props.usuario,
        visualizacoes: 0,
        foto: foto.name,
        publico: 1,
        criacao: new Date(),
      }).then(() => {
        setMsgTipo('sucesso');
        setCarregando(false);
      }).catch((erro) => {
        console.log('erro', erro);
        setMsgTipo('erro');
        setCarregando(false);
      });
    })
  }

  return (
    <div className="div-cel-cadastro">
      <Cabeçalho />
      <div className="col-12 pt-5 principal-cadastro">
        <form>
          <h1>Novo Canal</h1>
          <div className="form-group">
            <label>Nome do canal</label>
            <input
            className="form-control input-nome-canal"
            type="text"
            onChange={({ target }) => setTitulo( target.value )}
            />
          </div>

          <div className="form-group div-form-item">
            <label>Tipo de canal</label>
            <select onChange={({ target }) => {
              (target.value === 'Vídeo') && (alert('Desculpe, essa funcionalidade ainda não está disponível.'))
              setTipo( target.value );
              }} className="form-control">
              <option disabled selected value="defaulf">-- Selecione uma opção --</option>
              <option>Chat</option>
              <option>Vídeo</option>
            </select>
          </div>

          <div className="form-group div-form-item">
            <label>Descrição</label>
            <textarea onChange={({ target }) => setDescricao( target.value )} cols="30" rows="10" className="form-control"/>
          </div>

          <div className="form-group file">
            <label>Upload da foto:</label>
            <input
              type="file"
              className="form-control"
              onChange={({ target }) => setFoto( target.files[0] )}
            />
          </div >

          {(carregando) ? (
          <span>
            <div className="spinner-border text-primary spin-div" role="status">
              <span className="visually-hidden"></span>
            </div>
          </span> ) : (
          <button
            type="button"
            className="button-novo-canal mt-3 mb-5 mx-5"
            onClick={ cadastrar }
          >
            Cadastrar novo canal
          </button>
        )}
        </form>

        <div className="msg-login text-center mt-2">
          {(msgTipo === 'sucesso') && <span><strong>WoW! </strong>Canal cadastrado com sucesso! Verifique na sessão <Link to="/meus-canais/meus">meus canais</Link>.</span>}
          {(msgTipo === 'erro') && <span><strong>Ops!</strong>Não foi possível cadastrar esse canal. Tente novamente.</span>}               
        </div>
  
      </div>
      <Rodapé />
    </div>
  )
}



const mapStateToProps = (state) => ({
  usuario: state.usuarioReducer.usuarioEmail,
});


export default connect(mapStateToProps, null)(CadastroNovoCanal);