import React, { Component } from 'react';
import Login from '../components/Login';
import Cadastro from '../components/Cadastro';
import '../style/login.css';
import Cabeçalho from '../components/Cabeçalho';
import Rodapé from '../components/Rodapé';

class LoginPage extends Component {
  render() {
    return (
      <div className="principal-login-page">
        <Cabeçalho />
        <div className="componentes">
          <Cadastro />
          <Login />
        </div>
        <Rodapé />
      </div>
    )
  }
}

export default LoginPage;