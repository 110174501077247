import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GrView } from "react-icons/gr";
import { Link } from 'react-router-dom';
import firebase from '../config/firebase';
import 'firebase/storage';

function MeuCanalCard(props){
    const { id, img, titulo, detalhes, visualizacoes } = props;

    const [urlImagem, setUrlImagem ] = useState();

    useEffect(() => {
      firebase.storage().ref(`imagens/${img}`).getDownloadURL().then(url => setUrlImagem(url))
    }, [urlImagem]);

    return (
      <div className="col-md-3 col-sm-12 cartao-box">
        <img className="card-img-top img-cartao" src={ urlImagem } alt="imagem do canal" />

        <div className="card-body">
          <h5>{titulo}</h5>
            <p className="card-text text-justify">{ detalhes }</p>

          <div className="row rodape-card d-flex align-items-center">

          <div className="col-6">
            <Link to={`/detalhes-canal/` + id} className="btn btn-sm btn-detalhes">+ detalhes</Link>
          </div>

          <div className="col-6 text-rigth d-flex align-items-center">
            <GrView className="mx-2 text-primary" color="blue" size={ 20 }/>
            <span>{ visualizacoes }</span>
          </div>

          </div>
        </div>
      </div>
    )
}

const mapStateToProps = (state) => ({
  usuarioEmail: state.usuarioReducer.usuarioEmail,
  usuarioLogado: state.usuarioReducer.usuarioLogado,
});

export default connect(mapStateToProps, null)(MeuCanalCard);
