import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import firebase from '../config/firebase';
import 'firebase/auth';

function Login(props) {
  const [email, setEmail] = useState();
  const [senha, setSenha] = useState();
  const [msgTipo, setMsgTipo] = useState();
  const [carregando, setCarregando] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function logar() {
    setCarregando(true);

    if(!email || !senha) {
      setCarregando(false);
      setMsgTipo('erro');
    }

    firebase.auth().signInWithEmailAndPassword(email, senha).then((resultado) => {
      setCarregando(false);
      setMsgTipo('sucesso');
      setTimeout(() => {dispatch({ type: 'LOGIN_ACTION', email: email })}, 2000)
      
    }).catch((error) => {
      setMsgTipo('erro');
      console.log(error)

    })
  }

  return (
    <div className="div-principal-login">
        {
          props.usuarioLogado > 0 ? navigate('/home', { replace: true }) : null
        }
        <h1>faça seu login</h1>
        <form className="form-login">
          <input
            type="email"
            placeholder="Digite seu Email"
            onChange={({ target }) => setEmail( target.value )}
          />
          <input
            type="password"
            placeholder="Digite sua Senha"
            onChange={({ target }) => setSenha( target.value )}
          />
          {/* <button className="google-fb-button">
            <AiOutlineGoogle size={20} className="icon-google" />
            Fazer login com Google
          </button> */}
            <button
              className="entrar my-1"
              type="button"
              onClick={ () => logar() }
            >
              Entrar
            </button>
            <Link to="/recuperar-senha" className="p-rec-senha"><p>Recupere sua senha</p></Link>
        </form>

          {(carregando) && (
          <div className="spinner-border text-primary spin-div" role="status">
            <span className="visually-hidden"></span>
          </div>)}

          {(msgTipo === 'sucesso') && (
            <div className="ml-2">
              <span className="span-user-logado mt-2 ml-5">Usuário logado!</span>
              <br />
              <span className="span-user-redirecionar">Você será redirecionado.</span>
            </div>
          )}
          {(msgTipo === 'erro') && (<span className="span-user-redirecionar">Verifique seu usuário ou senha!</span>)}
      </div>
    )
  }

  const mapStateToProps = ({usuarioReducer : { usuarioLogado } }) => ({
    usuarioLogado: usuarioLogado,
  });

export default connect(mapStateToProps, null)(Login);