import React, { Component } from 'react';
import '../style/login.css';
import Canais from '../components/Canais';
import { connect } from 'react-redux';
import Cabeçalho from '../components/Cabeçalho';
import { Link } from 'react-router-dom';
import Rodapé from '../components/Rodapé';

class Principal extends Component {
  render() {
    const { usuario } = this.props;
    return (
      (usuario) ? (
        <div>
          <Cabeçalho />
          <div className="principal">
          <img className="img-principal" src={'https://hybri.io/assets/hero.png'} alt="" />
            <div className="div-novo-canal">
              <h1 className="h1-boas-vindas">Bem-vindo(a) à plataforma Hybri!</h1>
              <div>
              <p className="h2-novo-canal">Crie um novo canal clicando em:</p>
              <Link to="/cadastrar-novo-canal">
                <button className="novo-canal">Novo canal</button>
              </Link>
            </div>
            </div>
            <div className="div-canais-existentes">
              <h2 className="h2-canal-existente">Se conecte em um canal já existente:</h2>
              <Canais />
            </div>
          </div>
          <Rodapé />
        </div>
      ) : (
        <div>
          <Cabeçalho />
          <div className="usuario-nao-encontrado">
            <h1>Desculpe, usuário não encontrato!</h1>
            <h2>Volte para a página de login clicando em:</h2>
            <Link to="/"><button className="button-pag-login">Página de login</button></Link>
            <Rodapé />
          </div>
        </div>
      ) 
    )
  }
}

const mapStateToProps = (state) => ({
  usuario: state.usuarioReducer.usuarioEmail,
});


export default connect(mapStateToProps, null)(Principal);