import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from '../config/firebase';
import 'firebase/firestore';
import Cabeçalho from '../components/Cabeçalho';
import MeuCanalCard from '../components/MeuCanalCard';
import Rodapé from '../components/Rodapé';
import '../style/meusCanais.css';

function MeusCanais (props){
  const [ canais, setCanais ] = useState([]);
  const [ pesquisa, setPesquisa ] = useState('');
    let listaCanais = [];

    const location = useLocation();

    useEffect(() => {
      if(location.pathname) {
        firebase.firestore().collection('canais').where('usuario', '==', `${props.usuarioEmail}`).get().then( async (resultado) => {
          await resultado.docs.forEach((doc) => {
            if(doc.data().titulo.indexOf(pesquisa) >= 0) {
              listaCanais.push({
                id: doc.id,
                ...doc.data()
              })
            }
          })
  
          setCanais(listaCanais);
        })
      } else {
        firebase.firestore().collection('canais').get().then( async (resultado) => {
          await resultado.docs.forEach((doc) => {
            if(doc.data().titulo.indexOf(pesquisa) >= 0) {
              listaCanais.push({
                id: doc.id,
                ...doc.data()
              })
            }
          })
  
          setCanais(listaCanais);
        })
      }
    }, [pesquisa])


    return (
      <div>
        <Cabeçalho />
          <div className="principal-meus-canais-page">

            <div className="row p-3">
              <h3 className="mx-auto p-3 text-center">Canais</h3>
              <input
                type="text"
                className="form-control text-center"
                placeholder="Pesquisar canal pelo título"
                onChange={({ target }) => setPesquisa( target.value )}
              />
            </div>

          <div className="row meus-canais">
            {(canais.length === 0) && (
            <h3 className="text-center">Você ainda não criou nenhum canal!</h3>)}
            {canais.map((elem) => (
              <MeuCanalCard 
                id={elem.id}
                key={elem.id}
                img={elem.foto}
                titulo={elem.titulo}
                detalhes={elem.detalhes}
                visualizacoes={elem.visualizacoes}
              />
            ))}
          </div>
          </div>
        <Rodapé />
      </div>
    )
}

const mapStateToProps = (state) => ({
  usuarioEmail: state.usuarioReducer.usuarioEmail,
  usuarioLogado: state.usuarioReducer.usuarioLogado,
});

export default connect(mapStateToProps, null)(MeusCanais);