import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineMenu } from "react-icons/ai";
import '../style/cabeçalho.css';

function Cabeçalho(props) {

  const dispatch = useDispatch();

  const { usuarioEmail, usuarioLogado } = props;

    return (
      <nav className="div-cabecalho">
        <Link to="/home">
          <img src={'https://hybri.io/assets/logo.png'} alt="logo-hybri" className="logo-header"/>
        </Link>
        <h3>{ props.titulo }</h3>
        { usuarioLogado > 0 ? (
        <div className="div-usuario-botao">
          <AiOutlineMenu className="menu-hamburguer" size={45} />
          <h3 className="email">{ usuarioEmail }</h3>
          <Link to="/meus-canais/meus" className="nav-item">meus canais</Link>
          <Link to="/cadastrar-novo-canal" className="nav-item">criar canal</Link>
          <Link to="/" className="nav-item">
            <h3 className="nav-item" onClick={() => dispatch({ type: 'LOGOUT_ACTION' }) }
            > sair da conta</h3>
          </Link>
        </div>
        ) : (
          null
        )}
      </nav>
    )
}

const mapStateToProps = (state) => ({
  usuarioEmail: state.usuarioReducer.usuarioEmail,
  usuarioLogado: state.usuarioReducer.usuarioLogado,
});

export default connect(mapStateToProps, null)(Cabeçalho)