import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from '../config/firebase';
import 'firebase/storage';
import 'firebase/firestore';
import Cabeçalho from '../components/Cabeçalho';
import Rodapé from '../components/Rodapé';
import { GrView } from "react-icons/gr";
import { FaTicketAlt } from "react-icons/fa";
import '../style/detalhes.css';

function CanalDetalhes() {
  const location = useLocation();

  const locationId = location.pathname.split('/')[2];

  const [canal, setCanal] = useState();
  const [urlImage, setUrlImage] = useState();
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    if(carregando) {
      firebase.firestore().collection('canais').doc(locationId).get().then(resultado => {
        setCanal(resultado.data());

        firebase.firestore().collection('canais').doc(locationId).update('visualizacoes', resultado.data().visualizacoes + 1)
  
        firebase.storage().ref(`imagens/${resultado.data().foto}`).getDownloadURL().then(url => {
          setUrlImage(url);
          setCarregando(false);
        })
      })
    } else {
      firebase.storage().ref(`imagens/${canal.foto}`).getDownloadURL().then(url => setUrlImage(url))
    }
  },[])

  return (
    <div>
      <Cabeçalho />
        <div className="container-fluid principal-detalhes">
        {
          (carregando) ? (
          <div className="row py-5">
            <div className="spinner-border text-primary mx-auto" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
          ): (
            <div className="">
            <div className="row">
              <img src={urlImage} alt="banner" className="img-banner" />
            </div>


            <div className="todos-box"> 
            <div className="row box-detalhes mt-5 text-center box-info col-md-3 col-sm-12 mx-auto d-flex justify-content-around">
              <div className="col-md-3 col-sm-12 box-info p-3">
                <h5><strong>Título</strong></h5>
                <FaTicketAlt />
                <span className="mt-3">{canal.titulo}</span>
              </div>
            </div>

            <div className="row box-detalhes mt-5 text-center box-info col-md-3 col-sm-12 mx-auto d-flex justify-content-around" >
              <h5 className="text-center"><strong>Detalhes</strong></h5>
              <p className="text-justify p-3">{canal.descricao}</p>
            </div>

            <div className="row box-detalhes mt-5 text-center box-info col-md-3 col-sm-12 mx-auto d-flex justify-content-around">
              <h5 className="text-center"><strong>Visualizações</strong></h5>
              <GrView />
              <p className="text-justify p-3">{canal.visualizacoes + 1}</p>
            </div>
          </div>
          <div className="text-center mb-5">
          <Link to={ `/chat/` + canal.titulo }><button>Entrar no canal!</button></Link>
          </div>
          </div>
          )}
        </div>
      <Rodapé />
    </div>
  )
}



const mapStateToProps = (state) => ({
  usuario: state.usuarioReducer.usuarioEmail,
  usuarioLogado: state.usuarioReducer.usuarioLogado,
});


export default connect(mapStateToProps, null)(CanalDetalhes);