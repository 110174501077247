import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Principal from './pages/Principal';
import CadastroNovoCanal from './pages/CadastroNovoCanal';
import RecuperarSenha from './pages/RecuperarSenha';
import MeusCanais from "./pages/MeusCanais";
import CanalDetalhes from "./pages/CanalDetalhes";
import Chat from "./pages/Chat";
import Canal2 from "./pages/Canal2";

const App = () => {
   return(
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={ <LoginPage /> } />
          <Route exact path="/home" element={ <Principal /> } />
          <Route exact path="/recuperar-senha" element={ <RecuperarSenha /> } />
          <Route exact path="/canal-video" element={ <Canal2 /> } />
          <Route exact path="/meus-canais/:parametro" element={<MeusCanais />}/>
          <Route exact path="/cadastrar-novo-canal" element={ <CadastroNovoCanal/> } />
          <Route path="/detalhes-canal/:id" element={ <CanalDetalhes/> } />
          <Route path="/chat" element={ <Chat/> } />
          <Route path="/chat/:parametro" element={ <Chat/> } />
        </Routes>
       </BrowserRouter>
   )
}

export default App;
