import React from 'react';
import { FaRegSmileWink } from "react-icons/fa";
import Cabeçalho from '../components/Cabeçalho';
import '../style/rodape.css';

function Canal2() {
  return (
    <div className="">
      <Cabeçalho />
      <div className="text-center d-flex justify-content-center row align-items-center">
        <p className="p-pag-embreve">Essa funcionalidade será disponibilizada em breve!</p>
        <FaRegSmileWink />
      </div>
    </div>
    )
  }

export default Canal2;
