import React, { useState } from 'react';

import firebase from '../config/firebase';
import 'firebase/auth';

function Cadastro() {
  const [email, setEmail] = useState();
  const [senha, setSenha] = useState();
  const [msgTipo, setMsgTipo] = useState();
  const [msg, setMsg] = useState();
  const [carregando, setCarregando] = useState(false);
  
  function cadastrar() {
    setCarregando(true);
    setMsgTipo(null);

    if(!email || !senha) {
      setCarregando(false)
      setMsgTipo('erro');
      setMsg('Você precisa informar email e senha para realizar o cadastro!');
      return;
    }
    firebase.auth().createUserWithEmailAndPassword(email, senha).then((resultado) => {
      setCarregando(false);
      setMsgTipo('sucesso');
      setMsg('Cadastro realizado com sucesso!');
    }).catch((error) => {
      setCarregando(false);
      setMsgTipo('erro');
      switch(error.message){
        case 'The email address is badly formatted.':
          setMsg('É necessário cadastrar um email válido');
          break;
        case 'The email address is already in use by another account.':
          setMsg('Este email já está sendo utilizado em outra conta.');
          break;
        case 'Password should be at least 6 characters':
          setMsg('A senha deve ter pelo menos 6 caracteres.');
          break;
        default:
          setMsg('Não foi possível cadastrar. Tente novamente mais tarde.');
          break;
      }
    })
  }

  return (
    <div className="div-principal-cadastro">
      <h1>cadastre-se</h1>
      <form className="cadastro-form">
        <input
          type="email"
          placeholder="Email" 
          onChange={({ target }) => setEmail( target.value )}
          value={email}
        />
        <input
          type="password"
          placeholder="Senha"
          onChange={({ target }) => setSenha( target.value )}
          value={senha}
        />
        <button
          type="button"
          onClick={ () => { cadastrar(); setEmail(''); setSenha('') } }
          className="my-3"
        >
          Cadastrar
        </button>
      </form>
      {(carregando) && (
        <div className="spinner-border text-primary spin-div" role="status">
          <span className="visually-hidden"></span>
        </div>)}
      {(msgTipo === 'sucesso') && (<span className="span-user-redirecionar">{ msg }</span>)}
      {(msgTipo === 'erro') && (<span className="span-user-redirecionar">{ msg }</span>)}
    </div>
  )
}

export default Cadastro;