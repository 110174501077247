import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDduyOEG3g3P6jBJeD4Sw7FDph_xZ8haUc",
  authDomain: "hybri-d506f.firebaseapp.com",
  databaseURL: 'https://hybri-d506f-default-rtdb.firebaseio.com/',
  projectId: "hybri-d506f",
  storageBucket: "hybri-d506f.appspot.com",
  messagingSenderId: "95797056988",
  appId: "1:95797056988:web:0e7d15472fdc1132192b19",
  measurementId: "G-VGRM7K79DD"
};

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
